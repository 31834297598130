import './App.css';
import { HomePage } from './MainPages/HomePage';

function App() {
  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
