import React, { useEffect, useRef, useState } from 'react';
import './style.css';

export const HomePage = () => {
    const [isShopOpen, setIsShopOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleShop = () => {
        setIsShopOpen(!isShopOpen);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const movingItemsRef = useRef(null);

    useEffect(() => {
        const handleWheel = (event) => {
            if (movingItemsRef.current) {
                movingItemsRef.current.scrollLeft += event.deltaY;
            }
        };

        const currentElement = movingItemsRef.current;
        if (currentElement) {
            currentElement.addEventListener('wheel', handleWheel);

            return () => {
                currentElement.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);

    return (
        <div>

            <div class="marquee ">
                <span class="marquee__content">
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                    <span>
                        FREE GIFT ON ALL ORDERS OVER $60   •
                    </span>
                </span>


            </div>


            <nav className="navbar">
                <div className="menu">
                    <div className="menu-item" onMouseEnter={toggleShop} onMouseLeave={toggleShop}>
                        <a href="#shop" >SHOP+</a>
                    </div>



                    <a href="#about">bundles</a>

                </div>
                <div className="logo">
                    <a href="/">
                        <img src="/images/logo.png" alt="nourissentials" />

                    </a>
                </div>
                <div className="actions">
                    <a href="#cart">  <img src="/images/dollar.png" alt="$" /></a>

                    <a href="#account">  <img src="/images/account.png" alt="ACCOUNT" /></a>
                    <a href="#cart">  <img src="/images/cart.png" alt="CART" /></a>
                </div>
                <div className="toggle-icon" onClick={toggleMenu}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
                {isMenuOpen && (
                    <div className="dropdown-menu">
                        <a href="#home" onClick={toggleMenu}>Home</a>
                        <a href="#about" onClick={toggleMenu}>About</a>
                        <a href="#services" onClick={toggleMenu}>Services</a>
                        <a href="#contact" onClick={toggleMenu}>Contact</a>
                        <a href="#cart" onClick={toggleMenu}>Cart</a>
                        <a href="#account" onClick={toggleMenu}>Account</a>
                    </div>
                )}
                {isShopOpen && (
                    <div className="dropdown">
                        <div className="menu1">
                            <a href="#collections"><h1>All PRODUCTS</h1></a>
                            <a href="#shop-all"><h1>BUNDLES</h1></a>
                            <a href="#bundles"><h1>GUMMIES</h1></a>
                            <a href="#gummies"><h1>CAPSULES</h1></a>
                            <a href="#capsules"><h1>LIQUIDS</h1></a>
                        </div>
                        <div className="menuItems">
                            <div className="items">
                                <div className="item">
                                    <div className="image_div">
                                        <img src="/images/silajit.png" alt="" />
                                    </div>
                                    <h2>SHOP ALL</h2>
                                </div>
                                <div className="item">
                                    <div className="image_div">
                                        <img src="/images/silajit.png" alt="" />
                                    </div>
                                    <h2>SHOP ALL</h2>
                                </div>
                                <div className="item">
                                    <div className="image_div">
                                        <img src="/images/silajit.png" alt="" />

                                    </div>
                                    <h2>SHOP ALL</h2>
                                </div>
                                <div className="item">
                                    <div className="image_div">
                                        <img src="/images/silajit.png" alt="" />

                                    </div>
                                    <h2>SHOP ALL</h2>
                                </div>
                            </div>
                        </div>


                    </div>
                )}
            </nav>
            <div className="firstpage">
                <div className='left_part'>
                    <h1>
                        Pure <br />
                        form the nature
                    </h1>
                    <p>Clinically-studied ingredients that supports overall well being
                        with naturally sourced items.</p>
                    <button>SHOP ALL</button>
                </div>
                <div className="right_part">
                    <div className="stars-overlay">
                        <img src="/images/star.png" alt="Star" className="star star1" />
                        <img src="/images/star.png" alt="Star" className="star star2" />
                        <img src="/images/star.png" alt="Star" className="star star3" />
                    </div>
                    <img src="/images/lion.png" alt="Lion's Mane Extract" className="product-image" />
                </div>

            </div>


            <div className="second-div">
                <div className='image-container1'>
                    <img src="/images/greenstar.png" alt="" className='greenstar' />

                </div>
                <div className="text">
                    <h2>natural and organic supplement</h2>
                </div>
                <div className="image-container">
                    <img src="/images/bgtext.png" alt="Background" class="background-image" />
                    <img src="/images/inside.png" alt="Overlay" class="overlay-image" />
                </div>


            </div>

            <div className="third_page">
                <div className='heading'>
                    <div className='greenstar'>
                    </div>
                    <div className='title'>
                        <h2>Sugarfree Gummies</h2>
                    </div>
                    <div className='image'>
                        <div className='img1'>
                            <img src="/images/line.png" alt="" />

                        </div>
                        <div className='img2'>
                            <img src="/images/blackstar.png" alt="" />

                        </div>
                    </div>
                </div>

                <div className='products'>
                    <div className="moving_items">
                        <span>
                            <div className='product'>
                                <img src="/images/silajit.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - silajit + <br /> gummies</p>
                                    <p className='price'>$ 59.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/aswag.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - Aswa G +  <br /> gummies</p>
                                    <p className='price'>$ 39.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/seamoss.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - seamoss + <br /> gummies</p>
                                    <p className='price'>$ 49.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/silajit.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - silajit + <br /> gummies</p>
                                    <p className='price'>$ 59.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/aswag.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - Aswa G +  <br /> gummies</p>
                                    <p className='price'>$ 39.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/seamoss.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - seamoss + <br /> gummies</p>
                                    <p className='price'>$ 49.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/silajit.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - silajit + <br /> gummies</p>
                                    <p className='price'>$ 59.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/aswag.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - Aswa G +  <br /> gummies</p>
                                    <p className='price'>$ 39.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/seamoss.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - seamoss + <br /> gummies</p>
                                    <p className='price'>$ 49.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/silajit.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - silajit + <br /> gummies</p>
                                    <p className='price'>$ 59.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/aswag.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - Aswa G +  <br /> gummies</p>
                                    <p className='price'>$ 39.99</p>
                                </div>
                            </div>
                        </span>
                        <span>
                            <div className='product'>
                                <img src="/images/seamoss.png" alt="" />
                                <div>
                                    <p className='title'>nourissentials - seamoss + <br /> gummies</p>
                                    <p className='price'>$ 49.99</p>
                                </div>
                            </div>
                        </span>






                    </div>
                </div>
                <div className='bottom'>
                    <div className='image'>
                        <div className='img2'>
                            <img src="/images/blackstar.png" alt="" />

                        </div>
                        <div className='img1'>
                            <img src="/images/line.png" alt="" />

                        </div>

                    </div>

                </div>
                <img src="/images/green.png" alt="" className='bggreen' />
            </div>



            <div className="fourth_page">
                <div className="left">

                    <div className="moving_text">
                        <span className='text'>
                            <span>GMO FREE • VEGAN • SUSTANIABLE •</span>
                            <span>GMO FREE • VEGAN • SUSTANIABLE •</span>
                            <span>GMO FREE • VEGAN • SUSTANIABLE •</span>
                            <span>GMO FREE • VEGAN • SUSTANIABLE •</span>
                            <span>GMO FREE • VEGAN • SUSTANIABLE •</span>
                            <span>GMO FREE • VEGAN • SUSTANIABLE •</span>
                        </span>

                    </div>
                    <div className="features">
                        <div className="feature">
                            <img src="/images/GMOFree.png" alt="" />
                            <div className="texts">
                                <p className='title'>GMO free</p>
                                <p className='des'>Clinically-studied ingredients that supports overall well being
                                    with naturally sourced items.</p>
                            </div>
                        </div>
                        <div className="feature">
                            <img src="/images/sustaniable.png" alt="" />
                            <div className="texts">
                                <p className='title'>Sustainable practices</p>
                                <p className='des'>Clinically-studied ingredients that supports overall well being
                                    with naturally sourced items.</p>
                            </div>
                        </div>
                        <div className="feature">
                            <img src="/images/vegan.png" alt="" />
                            <div className="texts">
                                <p className='title'>Vegan capsules & gummies</p>
                                <p className='des'>Clinically-studied ingredients that supports overall well being
                                    with naturally sourced items.</p>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="right">
                    <div className="text">
                        <h2>RAW SILAJIT FROM THE HIMALAYAS</h2>
                    </div>
                    <div className="raw-Silajit" >

                    </div>
                </div>
                <img src="/images/blue.png" alt="" className='bgblue' />
            </div>



            <div className="fifth_page">
                <h1>Energies your self with naturally <br />
                    found vitamins and minerals.</h1>

                <img src="/images/group.png" alt="" />
            </div>


            <div className="sixthpage">
                <div className="gummies-left animated-gummies">
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                </div>
                <div className="content-center">
                    <div className="up">
                        <img src="/images/bggummy.png" alt="" />
                        <div className="text-overlay">
                            <h1>Nothing to hide here</h1>
                            <p>Ingredients you can pronounce. Quality you can trust.<br />Relief you can count on</p>
                        </div>
                    </div>
                    <div className="down">
                        <div className="features2">
                            <div className="feature">
                                <div className="image_div">
                                    <img src="/images/qualityAssurance.png" alt="" />
                                </div>

                                <h2>Quality Assurance</h2>
                                <p>3rd party tested for purity<br />
                                    and safety.</p>
                            </div>
                            <div className="feature">
                                <div className="image_div">
                                    <img src="/images/QualityManagement.png" alt="" />

                                </div>
                                <h2>Quality Management</h2>
                                <p>Made with ISO 9001 and<br />
                                    GMP practices.</p>
                            </div>
                            <div className="feature">
                                <div className="image_div">
                                    <img src="/images/Efficacy.png" alt="" />

                                </div>
                                <h2>Efficacy</h2>
                                <p>Our supplement all are proven<br />
                                    to have results.</p>

                            </div>
                            <div className="feature">
                                <div className="image_div">
                                    <img src="/images/RiskFree.png" alt="" />

                                </div>
                                <h2>Risk Free</h2>
                                <p>100% refund for <br /> returns.</p>
                            </div>
                            <div className="feature">
                                <div className="image_div">
                                    <img src="/images/NoPreservatives.png" alt="" />

                                </div>
                                <h2>No Preservatives</h2>
                                <p>Our products are free from<br />
                                    preservatives.</p>
                            </div>
                            <div className="feature">
                                <image className="image_div">
                                    <img src="/images/Natural.png" alt="" />

                                </image>
                                <h2>100% Natural</h2>
                                <p>All ingredients are natrually<br />
                                    sourced.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="gummies-right animated-gummies">
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                    <img src="/images/gummy.png" alt="" />
                </div>
            </div>

            <div className="seventhpage">
                <div className="box">
                    <div className="up">
                        <h1>Daily essentials with good intentions —<br />
                            for your overall well-being</h1>
                        <p>What we don’t add in our products</p>
                    </div>
                    <div className="down">
                        <div className="left">
                            <span>X<h2> HIGH FRUTOSE CORN SYRUP</h2></span>
                            <span>X<h2> GELATIN</h2></span>
                            <span>X<h2> SILICON DIOXIDE</h2></span>
                            <span>X<h2> GLUTEN & GMO</h2></span>
                        </div>
                        <div className="right">
                            <span>X  <h2> ARTIFICIAL SWEETNERS</h2></span>
                            <span>X  <h2> SYNTHETIC COLORS</h2></span>
                            <span>X  <h2> DAIRY</h2></span>
                            <span>X   <h2> FD&C DYES</h2></span>
                        </div>
                    </div>
                </div>



            </div>


            <footer className="footer">
                <div className="suscribeTitle">
                    <h1>Subscribe us for offers!</h1>
                </div>

                <div className="email-Section">
                    <h2>Subscribe us to get discounts, promo codes & free gifts!</h2>
                    <div className="email">
                        <input type="text" placeholder='your email address' />
                        <button><img src="" alt="" />Enter</button>
                    </div>
                </div>
                <div className="info">
                    <div className="media">
                        <div className="logo">
                            <img src="/images/logo.png" alt="" />
                        </div>
                        <div className="socialmedia">
                            <img src="/images/insta.png" alt="Insta" />
                            <img src="/images/x.png" alt="X" />
                            <img src="/images/facebook.png" alt="FB" />
                            <img src="/images/tiktok.png" alt="TT" />
                        </div>
                        <div className="textinfo">
                            <p>* These statements have not been evaluated by the
                                FDA. This product is not intended to diagnose, treat,
                                cure, or prevent any disease.</p>
                        </div>
                    </div>
                    <div className="myAccount">
                        <h1>MY ACCOUNT</h1>
                        <ul>
                            <li>Rewards</li>
                            <li>My Account</li>
                            <li>Gifts Cards</li>
                            <li>Order Protection</li>
                            <li>Claims</li>
                        </ul>

                    </div>
                    <div className="learn">
                        <h1>LEARN</h1>
                        <ul>
                            <li>Our Story</li>
                            <li>Education</li>
                            <li>Loyalty & <br />Rewards</li>
                        </ul>
                    </div>
                    <div className="gethelp">
                        <h1>GET HELP</h1>
                        <ul>
                            <li>Help/FAQ</li>
                            <li>Contact Us</li>
                            <li>Find Us</li>
                            <li>Affiliates & <br />Influencers</li>

                        </ul>
                    </div>
                </div>
                <div className='trademark'>
                    <p>© 2024 nouriessentials is a registered trademark of Arkuba Inc.</p>
                </div>


            </footer>
        </div>
    );
};
